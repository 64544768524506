import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  handleKeyUp = (e) => {
    if (e.code === "Enter") {
      const button = document.querySelector('button[type="submit"]');

      if (button) button.click();
    }
  };

  connect() {
    document.addEventListener("keyup", this.handleKeyUp);
  }

  disconnect() {
    document.removeEventListener("keyup", this.handleKeyUp);
  }
}
