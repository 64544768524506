import Swal from "sweetalert2";
import { Controller } from "@hotwired/stimulus";

const typeToIcon = {
  success: "info",
  error: "error",
  warning: "error",
  alert: "error",
  info: "info",
  notice: "info",
};

export default class extends Controller {
  static values = {
    message: String,
    type: String,
  };

  connect() {
    Swal.fire({
      title: this.messageValue,
      icon: typeToIcon[this.typeValue],
      toast: true,
      position: "top-right",
      iconColor: "white",
      customClass: {
        popup: "colored-toast",
      },
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });
  }
}
