import { Controller } from "@hotwired/stimulus";
import Swal from "sweetalert2";

export default class extends Controller {
  handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ff2c2c",
      confirmButtonText: "Yes, delete it!",
      customClass: {
        icon: "delete-icon",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        this.element.submit();
      }
    });
  };

  connect() {
    this.element.addEventListener("click", this.handleClick);
  }

  disconnect() {
    this.element.removeEventListener("click", this.handleClick);
  }
}
