import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "output"];

  handleChange = () => {
    this.outputTarget.innerText = this.inputTarget.value.length;
  };

  connect() {
    this.inputTarget.addEventListener("keyup", this.handleChange);
  }

  disconnect() {
    this.inputTarget.removeEventListener("keyup", this.handleChange);
  }
}
