import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    options: Array,
  };

  options = [];

  handleChange = (e) => {
    const results = this.search(e.target.value);
    const list = document.getElementById("autocomplete-list");

    list.innerText = "";

    if (results.length > 0 && e.target.value) {
      results.forEach((result) => {
        if (document.getElementById(result.email)) return;

        let item = document.createElement("li");
        const text = document.createTextNode(
          `${result.name} - ${result.email}`
        );

        item.appendChild(text);
        item.setAttribute("id", result.email);
        item.setAttribute("class", "autocomplete-result");

        item.addEventListener("click", () => {
          this.handleClick(result);
        });

        list.appendChild(item);
      });

      list.classList.remove("hidden");
    } else {
      list.textContent = "";
      list.classList.add("hidden");
    }
  };

  search = (query) => {
    return this.options.filter(
      (option) =>
        option.email.toLowerCase().includes(query.toLowerCase()) ||
        option.name.toLowerCase().includes(query.toLowerCase())
    );
  };

  handleClick = (option) => {
    this.element.value = option.email;
    this.element.parentElement.parentElement.requestSubmit();
  };

  connect() {
    this.options = this.optionsValue;
    this.element.addEventListener("keyup", this.handleChange);
  }

  disconnect() {
    this.element.removeEventListener("keyup", this.handleChange);
  }
}
