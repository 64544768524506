import { Controller } from "@hotwired/stimulus";
import lottie from "lottie-web";
import animation from "../confettiTwo.json";

function addAnimation(element) {
  const animItemX = lottie.loadAnimation({
    wrapper: element,
    animType: "svg",
    loop: false,
    autoplay: false,
    animationData: animation,
  });

  element.classList.remove("hide");

  animItemX.goToAndPlay(0, true);

  animItemX.addEventListener("complete", () => {
    element.classList.add("hide");
  });
}

export default class extends Controller {
  connect() {
    addAnimation(this.element);
  }
}
