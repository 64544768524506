import { Controller } from "@hotwired/stimulus";
import Swal from "sweetalert2";

export default class extends Controller {
  handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    Swal.fire({
      title: "Are you sure?",
      text: "This action will update your Google Calendar event and notify all of the recipients.",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#1071ff",
      confirmButtonText: "Yes, update meeting!",
      customClass: {
        icon: "info-icon",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        this.element.submit();
      }
    });
  };

  connect() {
    this.element.addEventListener("click", this.handleClick);
  }

  disconnect() {
    this.element.removeEventListener("click", this.handleClick);
  }
}
