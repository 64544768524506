import { Controller } from "@hotwired/stimulus";
import flatpickr from "flatpickr";

export default class extends Controller {
  static values = {
    defaultDate: String,
    minDate: String,
  };

  picker = null;

  connect() {
    this.picker = flatpickr(this.element, {
      altInput: true,
      enableDate: true,
      enableTime: true,
      altFormat: "l, J of M Y - H:i",
      dateFormat: "Y-m-d H:i",
      minDate: this.minDateValue,
      defaultDate: this.defaultDateValue,
      defaultMinute: 0,
      minuteIncrement: 15,
    });
  }

  disconnect() {
    this.picker.destroy();
  }
}
