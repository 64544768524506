// Entry point for the build script in your package.json
import "@hotwired/turbo-rails";
import jstz from "jstz";
import "./controllers";

const setCookie = (name, value) => {
  const expires = new Date();
  expires.setTime(expires.getTime() + 24 * 60 * 60 * 1000);
  document.cookie = `${name}=${value};expires=${expires.toUTCString()}`;
};

const findTimeZone = () => {
  const oldIntl = window.Intl;

  try {
    window.Intl = undefined;
    const tz = jstz.determine().name();
    window.Intl = oldIntl;
    return tz;
  } catch (e) {
    // sometimes (on android) you can't override intl
    return jstz.determine().name();
  }
};

const timezone = findTimeZone();

setCookie("timezone", timezone);
